@font-face {
  font-family: Term;
  src: local("Term"),
    url("./assets/fonts/Term/Term.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 600%;
}

h2 {
  font-size: 100%;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Background {
  width: 100%;
  height: 100vh;
}

.Content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: Term;
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .3);
}
